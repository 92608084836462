@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";

.requireditem {
  :global {
    .required-item-for-install-service {
      padding-right: 30px;
      padding-bottom: 30px;
      @media screen and (max-width: 767px) {
        padding-right: 10px;
      }
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        padding-right: 24px;
        padding-bottom: 15px;
      }
      &__img {
        width: 280px;
        height: 280px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        @media screen and (max-width: 1023px) {
          width: 166px;
          height: 166px;
        }
      }
      &__title {
        font-family: $font-medium;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.35px;
        color: $color-slate;
        padding-top: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        @media screen and (max-width: 1023px) {
          font-size: 16px;
          line-height: 24px;
        }
      }
      &__sku {
        font-family: $font-medium;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
        padding-top: 10px;
        @media screen and (max-width: 1023px) {
          font-size: 14px;
          line-height: 22px;
        }
      }
      &__price-quantity {
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
        @media screen and (max-width: 1280px) {
          flex-direction: column;
        }
      }
      &__price {
        font-family: $font-medium;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.55px;
        color: $color-slate;
        padding-top: 10px;
        font-weight: 500;
        @media screen and (max-width: 1023px) {
          font-size: 14px;
          line-height: 22px;
        }
        @media screen and (min-width: 767px) and (max-width: 1280px) {
          padding-top: 0px;
        }
      }
      &__discounted-price {
        display: flex;
        .price-with-discount {
          font-family: $font-medium;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.35px;
          color: $color-slate;
        }
        .original-price {
          font-family: $font-medium;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.35px;
          color: $color-dark-gray;
          margin-left: 5px;
          text-decoration: line-through;
        }
      }
      &__quantity {
        height: 40px;
        width: 90px;
        @media screen and (max-width: 1280px) {
          padding-top: 10px;
        }
      }
      &__include {
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        font-family: $font-medium;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.55px;
        color: $color-slate;
        @media screen and (max-width: 1023px) {
          flex-direction: column;
          font-size: 14px;
          line-height: 22px;
        }
      }
      .not-in-stock {
        font-family: $font-regular;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.33px;
        color: $color-dark-gray;
        padding-top: 10px;
      }
      .number-in-stock {
        font-family: $font-regular;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.33px;
        color: $color-error-red;
        display: flex;
        justify-content: flex-end;
        padding-top: 10px;
        &__msg {
          padding-top: 2px;
          margin-left: 4px;
        }
        img {
          width: 20px;
          height: 30px;
        }
      }
      .include-div {
        display: flex;
      }
      .price-gurd-link {
        font-family: $font-medium;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.3px;
        color: $color-slate;
        text-decoration: underline;
      }
      .inventory-loader {
        color: $color-dark-gray;
        font-family: $font-regular;
        font-size: 14px;
        display: flex;
        margin-top: 15px;
        gap: 5px;

        @media screen and (max-width: 767px) {
          gap: 0;
          margin-top: 30px;
          font-size: 12px;
        }
      }
    }
    .required-item-for-install-service-pdp {
      padding-right: 30px;
      padding-bottom: 30px;
      @media screen and (max-width: 767px) {
        padding-right: 0px;
      }
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        padding-right: 24px;
        padding-bottom: 15px;
      }
      &__img {
        width: 458px;
        height: 458px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        @media screen and (max-width: 1023px) {
          width: 166px;
          height: 166px;
        }
      }
      &__title {
        font-family: $font-medium;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.35px;
        color: $color-slate;
        padding-top: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        @media screen and (max-width: 1023px) {
          font-size: 16px;
          line-height: 24px;
        }
      }
      &__sku {
        font-family: $font-medium;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
        padding-top: 10px;
        @media screen and (max-width: 1023px) {
          font-size: 14px;
          line-height: 22px;
        }
      }
      &__price-quantity {
        display: flex;
        justify-content: space-between;
        padding-top: 0px;
        @media screen and (max-width: 1280px) {
          flex-direction: column;
        }
      }
      &__price {
        font-family: $font-medium;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.55px;
        color: $color-slate;
        padding-top: 10px;
        font-weight: 500;
        @media screen and (max-width: 1023px) {
          font-size: 14px;
          line-height: 22px;
        }
        @media screen and (min-width: 767px) and (max-width: 1280px) {
          padding-top: 0px;
        }
      }
      &__discounted-price {
        display: flex;
        .price-with-discount {
          font-family: $font-medium;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.35px;
          color: $color-slate;
        }
        .original-price {
          font-family: $font-medium;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.35px;
          color: $color-dark-gray;
          margin-left: 5px;
          text-decoration: line-through;
        }
      }
      &__quantity {
        height: 40px;
        width: 90px;
        @media screen and (max-width: 1280px) {
          padding-top: 10px;
        }
      }
      &__include {
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        font-family: $font-medium;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.55px;
        color: $color-slate;
        @media screen and (max-width: 1023px) {
          flex-direction: column;
          font-size: 14px;
          line-height: 22px;
        }
      }
      .not-in-stock {
        font-family: $font-regular;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.33px;
        color: $color-dark-gray;
        padding-top: 10px;
      }
      .number-in-stock {
        font-family: $font-regular;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.33px;
        color: $color-error-red;
        display: flex;
        justify-content: flex-end;
        padding-top: 10px;
        &__msg {
          padding-top: 2px;
          margin-left: 4px;
        }
        img {
          width: 20px;
          height: 30px;
        }
      }
      .include-div {
        display: flex;
      }
      .price-gurd-link {
        font-family: $font-medium;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.3px;
        color: $color-slate;
        text-decoration: underline;
      }
    }
  }
}
