@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";

.upsellmodal {
  :global {
    .upsell-modal {
      margin-top: 28px;
      margin-left: 50px;
      margin-bottom: 28px;
      margin-right: 28px;
      @media screen and (min-width: 1920px) {
        padding: 0 2.6rem;
        max-width: 20in;
        margin-right: auto;
        margin-left: auto;
      }
      @media screen and (max-width: 1200px) {
        margin-top: 16px;
        margin-left: 18px;
        margin-bottom: 50px;
        margin-right: 18px;
      }
    }
    .slick-arrow.slick-prev.slick-disabled {
      cursor: not-allowed;
      background-color: $color-dark-gray;
      &::before {
        background-color: $color-dark-gray;
        @media screen and (max-width: 767px) {
          background-color: #fff;
          color: #d3d3d3;
        }
      }
    }
    .slick-arrow.slick-next.slick-disabled {
      cursor: not-allowed;
      background-color: $color-dark-gray;
      &::before {
        background-color: $color-dark-gray;
        @media screen and (max-width: 767px) {
          background: #fff;
          color: #d3d3d3;
        }
      }
    }

    .upsellmodal-order-history {
      @media screen and (min-width: 1024px) and (max-width: 1399px) {
        margin-left: 25px;
        .upsell-modal-header {
          flex-direction: column;
          height: fit-content;
          &__rightside {
            display: flex;
            justify-content: flex-start;
          }
        }
        .upsell-modal__container {
          margin-top: 30px;
          .right-side {
            .installation-services {
              &__image {
                width: 369px;
                height: 246px;
              }
            }
          }
        }
      }
      @media screen and (min-width: 1199px) {
        .upsell-modal-header {
          &__title {
            font-size: 35px;
          }
        }
        .upsell-modal__container {
          margin-left: 0px;
          .right-side {
            padding-left: 45px;
          }
          .installation-services {
            display: flex;
            flex-direction: column;

            &__links {
              display: none;
            }
            &__right {
              margin-left: 0px;
              margin-top: 35px;
            }
            &__price--moblie {
              display: flex;
            }
            &__price {
              display: none;
            }
            &__add-to-cart {
              margin-top: 20px;
            }
            &__links--mobile {
              display: flex;
              justify-content: space-between;
            }
          }
          .upsell-modal-no-services {
            margin-left: 0px;
            margin-right: 0px;
          }
        }
        .slick-prev {
          left: -76px;
        }
        .slick-next {
          right: -54px;
        }
      }
    }
    .slick-prev {
      width: 60px;
      height: 60px;
      z-index: 1;
      left: 4px;
      @media screen and (min-width: 1200px) and (max-width: 1281px) {
        left: -48px;
      }
      @media screen and (max-width: 1200px) {
        transform: translate(-50%, -50%);
      }
      @media screen and (max-width: 767px) {
        top: 13%;
        left: 35%;
        left: -2%;
        margin-top: 20px;
        width: 35px !important;
        height: 35px !important;
        background: #fff;
        color: #000000;
      }
      &:before {
        width: 60px;
        height: 60px;
        background: $color-slate;
        content: "\e92c";
        font-size: 23px;
        justify-content: center;
        align-items: center;
        opacity: 1;
        display: flex;
        font-family: icomoon;
        color: $color-white;
        @media screen and (max-width: 767px) {
          width: 35px !important;
          height: 35px !important;
          background-color: #fff;
          color: #000000;
        }
      }
    }
    .slick-next {
      width: 60px;
      height: 60px;
      @media screen and (max-width: 1200px) {
        transform: translate(-50%, -50%);
      }
      @media screen and (max-width: 767px) {
        right: -10%;
        margin-top: 20px;
        width: 35px !important;
        height: 35px !important;
        top: 13%;
      }
      &:before {
        width: 60px;
        height: 60px;
        background: $color-slate;
        content: "\e92c";
        font-size: 23px;
        justify-content: center;
        align-items: center;
        opacity: 1;
        display: flex;
        font-family: icomoon;
        color: $color-white;
        transform: rotate(-180deg);
        @media screen and (max-width: 767px) {
          margin-top: 0px;
          width: 35px !important;
          height: 35px !important;
          color: #000000;
          background: #fff;
        }
      }
    }
    .upsell-modal-header {
      display: flex;
      justify-content: space-between;
      height: 90px;
      @media screen and (max-width: 767px) {
        // height: 190px;
        flex-direction: column;
      }
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        height: 126px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
      &__title {
        font-family: $font-light;
        font-size: 45px;
        line-height: 56px;
        letter-spacing: -3.19px;
        color: $color-slate;
        margin-top: 28px;
        @media screen and (max-width: 767px) {
          font-size: 28px;
          line-height: 36px;
          letter-spacing: -1.98px;
          margin-top: 15px;
        }
      }
      &__rightside {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        @media screen and (max-width: 767px) {
          flex-wrap: wrap;
          justify-content: flex-start;
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          display: flex;
          margin-bottom: 20px;
        }
        &__zipcode-msg {
          font-family: $font-medium;
          color: $color-dark-gray;
          font-size: 16px;
          line-height: 24px;
          margin-top: 25px;
          margin-right: 20px;
          @media screen and (max-width: 767px) {
            margin-bottom: 20px;
            margin-top: 10px;
          }
        }
        .rightside-for-mobile {
          display: flex;
          @media screen and (max-width: 767px) {
            display: flex;
          }
        }
        .zipcode-if-available {
          font-family: $font-light;
          font-size: 32px;
          line-height: 40px;
          letter-spacing: -1.06px;
          color: $color-slate;
          margin-top: 10px;
        }
        .zipcode-change-link {
          font-size: 14px;
          line-height: 16px;
          letter-spacing: -0.28px;
          color: $color-slate;
          font-family: $font-medium;
          margin-top: 27px;
          margin-left: 20px;
          border-bottom: 1px solid;
          margin-bottom: 39px;
          cursor: pointer;
        }
        .zipcode-serach-link {
          font-size: 14px;
          line-height: 16px;
          letter-spacing: -0.28px;
          color: $color-slate;
          font-family: $font-medium;
          margin-top: 27px;
          margin-left: 20px;
          border-bottom: 1px solid;
          margin-bottom: 39px;
        }
        .input-field {
          width: 105px;
          height: 60px;
        }
        .input-field__border {
          padding: 5px 8px;
        }
      }
    }
    .upsell-modal__container {
      margin-top: 15px;
      margin-left: 75px;
      margin-right: 50px;
      margin-bottom: 50px;
      display: flex;
      @media screen and (max-width: 767px) {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        display: flex;
        flex-direction: column;
      }

      @media screen and (min-width: 768px) and (max-width: 1200px) {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
      }
      @media screen and (min-width: 1201px) and (max-width: 1399px) {
        margin-left: 20px;
      }
    }
    .left-side {
      padding-right: 20px;
      @media screen and (max-width: 767px) {
        width: fit-content;
      }
      .upsell-modal-middle-border {
        border-right: 1px solid $color-light-gray;
      }
      .left-side--moblie {
        @media screen and (max-width: 767px) {
          display: flex;
        }
      }
      .prouct-info--mobile {
        @media screen and (max-width: 767px) {
          margin-top: 20px;
          margin-left: 40px;
        }
      }
      .head {
        font-family: $font-light;
        color: $color-slate;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: -1.5px;
        @media screen and (max-width: 1200px) {
          font-size: 20px;
          line-height: 26px;
          letter-spacing: -1.5px;
        }
      }
      .image {
        height: 280px;
        width: 280px;
        margin-top: 40px;
        margin-bottom: 10px;
        @media screen and (max-width: 767px) {
          width: 157px;
          height: 157px;
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          height: 166px;
          width: 166px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .product-name {
        font-family: $font-regular;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.82px;
        color: $color-slate;
        @media screen and (max-width: 1200px) {
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.73px;
        }
      }
      .product-description {
        margin-top: 14px;
        font-family: $font-regular;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
        margin-right: 50px;
        @media screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.48px;
        }
      }
      .product-sku {
        font-family: $font-regular;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.45px;
        color: $color-dark-gray;
        margin-right: 50px;
        margin-top: 14px;
        @media screen and (max-width: 767px) {
          font-size: 12px;
          line-height: 18px;
          letter-spacing: -0.39px;
        }
      }
      .product-price {
        font-family: $font-medium;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.8px;
        color: $color-slate;
        margin-right: 50px;
        margin-top: 14px;
        @media screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.7px;
        }
      }
      .product-qty {
        font-family: $font-medium;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.8px;
        color: $color-slate;
        margin-right: 50px;
        margin-top: 14px;
        @media screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.7px;
        }
      }
      .pdp-upsell {
        display: none;
      }
    }
    .right-side {
      padding-left: 65px;
      border-left: 1px solid $color-light-gray;
      width: 100%;
      @media screen and (max-width: 767px) {
        padding-left: 5px;
        border-left: none;
        border-top: 1px solid $color-light-gray;
      }
      @media screen and (min-width: 768px) and (max-width: 1200px) {
        padding-left: 30px;
        border-left: 1px solid $color-light-gray;
      }
      @media screen and (min-width: 1201px) and (max-width: 1399px) {
        padding-left: 25px;
      }

      .title {
        font-family: $font-light;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: -1.5px;
        color: $color-slate;
        @media screen and (max-width: 1200px) {
          font-size: 20px;
          line-height: 26px;
          letter-spacing: -1.15px;
        }
        @media screen and (max-width: 767px) {
          margin-top: 25px;
        }
      }
      .description {
        font-family: $font-regular;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.65px;
        color: $color-dark-gray;
        margin-top: 15px;
        @media screen and (max-width: 1200px) {
          margin-top: 25px;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.45px;
          margin-top: 10px;
        }
      }
      .installation-services {
        margin-top: 30px;
        display: flex;
        @media screen and (min-width: 1025px) and (max-width: 1200px) {
          flex-direction: column;
        }
        @media screen and (max-width: 767px) {
          flex-direction: column;
        }
        &__image {
          width: 553px;
          height: 373px;
          @media screen and (max-width: 767px) {
            width: 340px;
            height: 239px;
          }
          @media screen and (min-width: 790px) and (max-width: 1024px) {
            width: 262px;
            height: 175px;
          }
          @media screen and (min-width: 768px) and (max-width: 769px) {
            height: 262px;
            width: 262px;
          }
          @media screen and (min-width: 1025px) and (max-width: 1200px) {
            width: 100%;
            height: 373px;
          }
          @media screen and (min-width: 1201px) and (max-width: 1280px) {
            width: 400px;
            height: 300px;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }
        &__links {
          display: flex;
          justify-content: space-between;
          @media screen and (max-width: 767px) {
            display: none;
          }
          @media screen and (min-width: 768px) and (max-width: 1024px) {
            flex-direction: column;
            padding-bottom: 10px;
          }
          @media screen and (min-width: 1024px) and (max-width: 1280px) {
            padding-bottom: 10px;
          }
        }
        &__links--mobile {
          display: none;
          @media screen and (max-width: 1200px) {
            display: flex;
            justify-content: space-between;
            margin-bottom: 55px;
          }
          @media screen and (min-width: 767px) and (max-width: 1280px) {
            display: none;
          }
        }

        &__learn-more {
          font-family: $font-medium;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: -0.28px;
          color: $color-slate;
          margin-top: 15px;
          text-decoration: underline;
          width: fit-content;
        }
        &__price-guard {
          font-family: $font-medium;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: -0.28px;
          color: $color-slate;
          margin-top: 15px;
          text-decoration: underline;
          width: fit-content;
        }
        &__right {
          margin-left: 40px;
          @media screen and (min-width: 1025px) and (max-width: 1200px) {
            margin-left: 0px;
          }
          @media screen and (max-width: 767px) {
            margin-left: 0px;
            margin-top: 20px;
          }
          @media screen and (min-width: 1800px) {
            width: 560px;
          }
        }
        &__right--mobile {
          display: flex;
          justify-content: space-between;
        }
        &__title {
          font-family: $font-medium;
          font-size: 20px;
          line-height: 28px;
          letter-spacing: -0.4px;
          color: $color-slate;
          @media screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.35px;
          }
        }
        &__description {
          font-family: $font-regular;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.55px;
          color: $color-dark-gray;
          margin-top: 22px;
        }
        &__sku {
          font-family: $font-regular;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.55px;
          padding-top: 20px;
          color: $color-dark-gray;
          @media screen and (max-width: 767px) {
            margin-top: 0px;
          }
        }
        &__price {
          font-family: $font-regular;
          font-size: 24px;
          line-height: 28px;
          letter-spacing: -0.48px;
          color: $color-slate;
          margin-top: 20px;
          margin-bottom: 20px;
          font-weight: 600;
          @media screen and (max-width: 767px) {
            display: none;
          }
        }
        &__price--moblie {
          display: none;
          @media screen and (max-width: 767px) {
            display: flex;
            justify-content: space-between;
            font-family: $font-regular;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.8px;
            color: $color-slate;
            font-weight: 600;
          }
        }
        &__add-to-cart {
          display: flex;
          @media screen and (max-width: 1200px) {
            margin-top: 30px;
          }
          @media screen and (min-width: 767px) and (max-width: 1024px) {
            display: none;
          }
        }
        &__quantity-selector {
          height: 40px;
          width: 90px;
        }
        .add-to-cart {
          width: 100%;
          padding-bottom: 10px;
          .button {
            height: 40px;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.25px;
            padding-left: 20px;
          }
        }
        &__tool-tip {
          margin-top: 30px;
          font-family: $font-medium;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.45px;
          color: $color-dark-gray;
          text-decoration: underline;
          width: fit-content;
          cursor: pointer;
          @media screen and (max-width: 767px) {
            margin-top: 25px;
          }
          @media screen and (min-width: 1025px) and (max-width: 1200px) {
            margin-top: 15px;
            padding-bottom: 80px;
          }
          @media screen and (min-width: 768px) and (max-width: 1024px) {
            display: none;
          }
        }
        .--show {
          display: block;
          position: relative;
        }

        .--hide {
          display: none;
        }

        &__tool-tip-wrapper {
          position: absolute;
          z-index: 9;
          top: -18px;
          left: 147px;
          @media screen and (max-width: 767px) {
            top: -48px;
            left: 108px;
          }
          @media screen and (min-width: 768px) and (max-width: 1200px) {
            top: -49px;
            left: 149px;
          }
        }
        .tooltip__container {
          padding: 10px 15px;
        }
      }
    }
    .installation-services__mobile-landscpae {
      display: none;
      @media screen and (min-width: 767px) and (max-width: 1024px) {
        display: block;
      }
      .mobile-landscpae {
        &__add-to-cart {
          display: flex;
        }
        &__quantity-selector {
          height: 40px;
          width: 90px;
        }
        &__button-to-cart {
          width: 100%;
          .button {
            height: 40px;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.25px;
            padding-left: 20px;
          }
        }
        &__tool-tip {
          margin-top: 30px;
          font-family: $font-medium;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.45px;
          color: $color-dark-gray;
          border-bottom: 1px solid $color-dark-gray;
          width: fit-content;
          cursor: pointer;
        }
        .--show {
          display: block;
          position: relative;
        }

        .--hide {
          display: none;
        }
        &__tool-tip-wrapper {
          position: absolute;
          z-index: 9;
          top: -18px;
          left: 147px;
          @media screen and (max-width: 767px) {
            top: -48px;
            left: 108px;
          }
          @media screen and (min-width: 768px) and (max-width: 1200px) {
            top: -49px;
            left: 149px;
          }
        }
        .tooltip__container {
          padding: 10px 15px;
        }
        &__links--mobile {
          display: none;
          @media screen and (max-width: 767px) {
            display: flex;
            justify-content: space-between;
            margin-bottom: 40px;
          }
        }
        &__learn-more {
          font-family: $font-medium;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: -0.28px;
          color: $color-slate;
          margin-top: 15px;
          border-bottom: 1px solid $color-slate;
        }
        &__price-guard {
          font-family: $font-medium;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: -0.28px;
          color: $color-slate;
          margin-top: 15px;
          border-bottom: 1px solid $color-slate;
        }
      }
    }

    .required-parts-right-side {
      padding-bottom: 40px;
      @media screen and (max-width: 1280px) {
        padding-bottom: 0px;
      }
      .required-item-title {
        font-family: $font-light;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: -1.5px;
        color: $color-slate;
        @media screen and (max-width: 1024px) {
          font-size: 20px;
          line-height: 26px;
        }
        @media screen and (max-width: 767px) {
          padding-top: 25px;
        }
      }
      .required-item-header {
        display: flex;
        justify-content: space-between;
      }
      .subtotal-add-to-cart {
        @media screen and (max-width: 1024px) {
          display: none;
        }
        display: flex;
        .required-item-price {
          font-family: $font-regular;
          font-size: 24px;
          line-height: 30px;
          letter-spacing: -0.9px;
          color: $color-slate;
          padding-right: 20px;
          padding-top: 5px;
        }
        .selected {
          font-family: $font-regular;
          font-size: 22px;
          line-height: 30px;
          letter-spacing: -0.75px;
          color: $color-slate;
          padding-right: 20px;
        }
        .add-to-cart {
          .button {
            height: 40px;
            font-size: 14px;
            width: 154px;
          }
        }
      }
      .required-item {
        overflow-x: auto;
        max-width: 100%;
        margin: inherit;
        &::-webkit-scrollbar-button {
          width: 0px;
        }
        &::-webkit-scrollbar {
          height: 5px;
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 2px $color-light-gray;
          border-radius: 5px;
          background-color: $color-light-gray;
        }
        &::-webkit-scrollbar-thumb {
          background: $color-dark-gray;
          border-radius: 5px;
        }
      }
      .required-item-list {
        padding-top: 40px;
        width: 280px;
        display: flex;
        @media screen and (max-width: 1023px) {
          width: 166px;
          padding-bottom: 0px;
        }
      }
      .required-item-install-service {
        padding-right: 30px;
        padding-bottom: 30px;
        @media screen and (max-width: 767px) {
          padding-right: 10px;
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          padding-right: 24px;
          padding-bottom: 15px;
        }
        &__img {
          width: 280px;
          height: 280px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          @media screen and (max-width: 1023px) {
            width: 166px;
            height: 166px;
          }
        }
        &__title {
          font-family: $font-medium;
          font-size: 18px;
          line-height: 26px;
          letter-spacing: -0.35px;
          color: $color-slate;
          padding-top: 10px;

          @media screen and (max-width: 1023px) {
            font-size: 16px;
            line-height: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
        &__sku {
          font-family: $font-medium;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.55px;
          color: $color-dark-gray;
          padding-top: 10px;
          @media screen and (max-width: 1023px) {
            font-size: 14px;
            line-height: 22px;
          }
        }
        &__price-quantity {
          display: flex;
          justify-content: space-between;
          padding-top: 10px;
          @media screen and (max-width: 1280px) {
            flex-direction: column;
          }
        }
        &__price {
          font-family: $font-medium;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.55px;
          color: $color-slate;
          padding-top: 10px;
          font-weight: 500;
          @media screen and (max-width: 1023px) {
            font-size: 14px;
            line-height: 22px;
          }
          @media screen and (min-width: 767px) and (max-width: 1280px) {
            padding-top: 0px;
          }
        }
        &__discounted-price {
          display: flex;
          .price-with-discount {
            font-family: $font-medium;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.35px;
            color: $color-slate;
          }
          .original-price {
            font-family: $font-medium;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.35px;
            color: $color-dark-gray;
            margin-left: 5px;
            text-decoration: line-through;
          }
        }
        &__quantity {
          height: 40px;
          width: 90px;
          @media screen and (max-width: 1280px) {
            padding-top: 10px;
          }
        }
        &__include {
          padding-top: 20px;
          display: flex;
          justify-content: space-between;
          font-family: $font-medium;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.55px;
          color: $color-slate;
          @media screen and (max-width: 1024px) {
            flex-direction: column;
            font-size: 14px;
            line-height: 22px;
          }
        }
        .include-div {
          display: flex;
        }
        .price-gurd-link {
          font-family: $font-medium;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.3px;
          color: $color-slate;
          text-decoration: underline;
        }
      }
      .required-item-for-install-service {
        padding-right: 30px;
        padding-bottom: 30px;
        @media screen and (max-width: 767px) {
          padding-right: 10px;
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          padding-right: 24px;
          padding-bottom: 15px;
        }
        &__img {
          width: 280px;
          height: 280px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          @media screen and (max-width: 1023px) {
            width: 166px;
            height: 166px;
          }
        }
        &__title {
          font-family: $font-medium;
          font-size: 18px;
          line-height: 26px;
          letter-spacing: -0.35px;
          color: $color-slate;
          padding-top: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          @media screen and (max-width: 1023px) {
            font-size: 16px;
            line-height: 24px;
          }
        }
        &__sku {
          font-family: $font-medium;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.55px;
          color: $color-dark-gray;
          padding-top: 10px;
          @media screen and (max-width: 1023px) {
            font-size: 14px;
            line-height: 22px;
          }
        }
        &__price-quantity {
          display: flex;
          justify-content: space-between;
          padding-top: 10px;
          @media screen and (max-width: 1280px) {
            flex-direction: column;
          }
        }
        &__price {
          font-family: $font-medium;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.55px;
          color: $color-slate;
          padding-top: 10px;
          font-weight: 500;
          @media screen and (max-width: 1023px) {
            font-size: 14px;
            line-height: 22px;
          }
          @media screen and (min-width: 767px) and (max-width: 1280px) {
            padding-top: 0px;
          }
        }
        &__discounted-price {
          display: flex;
          .price-with-discount {
            font-family: $font-medium;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.35px;
            color: $color-slate;
          }
          .original-price {
            font-family: $font-medium;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.35px;
            color: $color-dark-gray;
            margin-left: 5px;
            text-decoration: line-through;
          }
        }
        &__quantity {
          height: 40px;
          width: 90px;
          @media screen and (max-width: 1280px) {
            padding-top: 10px;
          }
        }
        &__include {
          padding-top: 20px;
          display: flex;
          justify-content: space-between;
          font-family: $font-medium;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.55px;
          color: $color-slate;
          @media screen and (max-width: 1023px) {
            flex-direction: column;
            font-size: 14px;
            line-height: 22px;
          }
        }
        .not-in-stock {
          font-family: $font-regular;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.33px;
          color: $color-dark-gray;
          padding-top: 10px;
        }
        .number-in-stock {
          font-family: $font-regular;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.33px;
          color: $color-error-red;
          display: flex;
          justify-content: flex-end;
          padding-top: 10px;
          &__msg {
            padding-top: 2px;
            margin-left: 4px;
          }
          img {
            width: 20px;
            height: 30px;
          }
        }
        .include-div {
          display: flex;
        }
        .price-gurd-link {
          font-family: $font-medium;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.3px;
          color: $color-slate;
          text-decoration: underline;
        }
      }
    }
    .subtotal-add-to-cart--mobile {
      display: none;
      @media screen and (max-width: 1024px) {
        padding-bottom: 70px;
      }
      @media screen and (max-width: 1024px) {
        display: block;
        .number-total-mobile {
          display: flex;
          justify-content: space-between;
          padding-top: 20px;
        }
        .add-to-cart- {
          padding-top: 20px;
        }
        .selected-mobile {
          font-family: $font-regular;
          font-size: 22px;
          line-height: 30px;
          letter-spacing: -0.75px;
          color: $color-slate;
        }
        .total-price {
          font-family: $font-regular;
          font-size: 24px;
          line-height: 30px;
          letter-spacing: -0.5px;
          color: $color-slate;
        }
      }
    }
  }
}
